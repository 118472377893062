import React from "react";

import { Link } from "gatsby";

import './Apost.scss'



const Apost = (props) => {
  return (
    <div className="post">

      <div className="breadcrumb">
        <Link to="/">home&nbsp;>&nbsp;</Link>
        <Link to="/blog">blog&nbsp;>&nbsp;</Link>
        {/* <span>
          {window.location.href}
        </span> */}
        <span>
          {typeof window !== "undefined"
            ? window.location.href.split("/")[
            window.location.href.split("/").length - 1
            ]
            : ""}
        </span>
      </div>


      <h1>{props.data.markdownRemark.frontmatter.title}</h1>

      <div className="timestamp">
        <span className="timestamp__date">
          <span className="posticon" role="img" aria-label="calender post">
            📅
          </span>
          {props.data.markdownRemark.frontmatter.date}
        </span>

        <span className="timestamp__read">
          <span className="posticon" role="img" aria-label="calender watch post">
            ⌚
          </span>
          {props.data.markdownRemark.frontmatter.readtime}
        </span>
      </div>

      {/* we use dangerously set inner html to add content from markdown file */}
      <div className="dangerHtml"
        dangerouslySetInnerHTML={{
          __html: props.data.markdownRemark.html,
        }}
      ></div>

      <div className="tags">
        <span>
          Tags:
        </span>
        {props.data.markdownRemark.frontmatter.tags.map((tag) => (
          <span key={tag}>{tag}</span>
        ))}
      </div>
    </div>
  );
};

export default Apost;
