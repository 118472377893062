import { graphql } from "gatsby";
import React from "react";

import Layout from "../components/Layout";

import Apost from "../components/Blog/Apost";



//here we exporting the qurey to make props in Blog component under this
// This $slug is from environment variable
export const qurey = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date
        tags
        readtime
      }
      html
    }
  }
`;

//a single full blog
const Blog = (props) => {
  return (
    <Layout>
      <Apost data={props.data} />
    </Layout>
  );
};

export default Blog;

      // <Head
      //   title={`${props.data.markdownRemark.frontmatter.title}`}
      //   >
      //   </Head>